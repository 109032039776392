// Color Pallete 🖌

///////////////////////////////////////////

.spin {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  border-radius: 4px;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .spin {
    animation: logo-spin infinite 20s linear;
  }
}

@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
